<template>
    <div class="journalDetail">
        <div class="journal-nav">
            <div>
                <img :src="info.employee_avatar">
            </div>
            <div>{{info.employee_name}}</div>
            <!-- <div class="journal-nav-time">2020-10-11</div> -->
        </div>
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="listFinishedText"
            @load="onLoad"
            >
            <div class="journal-content" v-for="(item,index) in list" :key="item.journal_id">
                <h1>{{item.title}}</h1>
                <div class="journal-content-wrap">
                    <van-row>
                        <van-col span="6">校总跟:{{item.follow_total.studio_follow_total}}</van-col>
                        <van-col span="6">校总诺:{{item.follow_total.studio_promise_total}}</van-col>
                        <van-col span="6">个总跟:{{item.follow_total.employee_follow_total}}</van-col>
                        <van-col span="6">个总诺:{{item.follow_total.employee_promise_total}}</van-col>
                    </van-row>
                    <div v-if="item.channel_follow_list && item.channel_follow_list.length > 0">
                        <div class="journal-content-title">渠道: </div>
                        <div v-for="(it,j) in item.channel_follow_list" :key="j">
                            {{j+1}}. {{it.channel_name}} * {{it.follow_count}}
                        </div>
                    </div>
                    <div v-if="item.target_customer && item.target_customer.length > 0">
                        <div class="journal-content-title">靶子: </div>
                        <div v-for="(it,j) in item.target_customer" :key="j">
                            {{j+1}}. {{it.customer_name}}: {{it.customer_cellphone}}, {{it.customer_remark}}
                        </div>
                    </div>
                </div>
                <div>
                    <div class="journal-content-title" v-if="item.category_id == 1">今日工作内容:</div> 
                    <div class="journal-content-title" v-if="item.category_id == 2">本周工作内容:</div> 
                    <div class="journal-content-title" v-if="item.category_id == 3">本月工作内容:</div> 
                    <div style="white-space: pre-line">{{item.content}}</div>
                </div>
                <div>
                    <div class="journal-content-title" v-if="item.category_id == 1">明日工作内容:</div> 
                    <div class="journal-content-title" v-if="item.category_id == 2">下周工作内容:</div> 
                    <div class="journal-content-title" v-if="item.category_id == 3">下月工作内容:</div> 
                    <div style="white-space: pre-line">{{item.tomorrow}}</div>
                </div>
                <div>
                    <div class="journal-content-title">遇到的问题: </div>
                    <div style="white-space: pre-line">{{item.question}}</div>
                </div>
                <div style="text-align:right">
                    <van-button @click="showAnswer(index,1,item)" size="small" type="primary">评论</van-button>
                </div>
                <div v-if="isAnswer == index" class="journal-content-textarea">
                    <van-field :border='false' v-model="answerText" :autosize="{ maxHeight: 50 }" type="textarea" placeholder="请输入留言"/>
                    <div style="text-align:right">
                        <van-button @click="isAnswer = -1;answerText = ''" size="small">取消</van-button>
                        <van-button :disabled='isAddAnswer' @click="toComment(item)" style="margin:0 5px 5px 5px" size="small" type="primary">回复</van-button>
                    </div>
                </div>
                <div class="journal-comments-item">
                    <div v-for="(it,ind) in item.journal_comments" :key="it.commnet_id" class="journal-comments-wrap">
                        <div class="journal-comments-wrap-nav">
                            <img :src="it.commnet_avatar">
                            <span>{{it.commnet_name}}</span>
                        </div>
                        <div class="journal-comments-wrap-content">
                            <div :ref='it.commnet_id' @click="showAnswer(index,2,item,it)">
                                <span>{{it.commnet_content}}</span>
                            </div>
                            <div :ref='con.commnet_id' @click="showAnswer(index,3,item,it,con)" v-for="(con,i) in it.commnet_children" :key="con.commnet_id">
                                <span>{{con.commnet_name}} 回复 {{it.commnet_name}}: {{con.commnet_content}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <van-empty v-if="list.length == 0" description="暂无相关数据~" />
        <loadings :loading='searchLoading' />
    </div>
</template>

<script>
import { Empty, Col, Row, Field, Form } from 'vant'
import Loadings from '@/components/loading'
    export default {
        name:'journalDetail',
        data() {
            return {
                loading:false,
                finished: false,
                searchLoading: true,
                isAddAnswer: false,
                list:[],
                listFinishedText: '没有跟多了',
                pageSize: 10,
                pageCurrent: 1,
                info:{},
                hoverIndex:[-1,-1,-1],
                isAnswer:-1,
                answerText:'',
                answerObj:{},
            }
        },
        components: {
            [Col.name]: Col,
            [Row.name]: Row,
            [Field.name]: Field,
            [Form.name]: Form,
            [Empty.name]: Empty,
            loadings:Loadings,
        },
        methods: {
            getList(){
                this.$api.custom_huidong_journal_list({ 'per-page': this.pageSize, page: this.pageCurrent,employee_id:this.$route.query.employee_id})
                .then(res=>{
                    this.loading = false
                    this.searchLoading = false
                    this.info = res._data
                    this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
                    if(this.list.length > 0 && this.pageCurrent == 1 && this.list[0].is_read !=1){
                        this.toSeen(this.list[0].journal_id)
                    }
                    this.finished = this.list.length >= res._meta.totalCount
                    this.listFinishedText = this.list.length ? '没有更多了~' : ''
                    this.pageCurrent += 1
                    this.$nextTick(()=>{
                        this.toAnchor()
                    })
                })
            },
            toSeen(id){
                this.$api.custom_huidong_journal_seen({journal_id:id})
                .then(res=>{})
            },
            onLoad() {
                this.getList()
            },
            toComment(item){
                if(!this.answerText){
                    this.$message.warning('请输入回复内容~')
                    return false
                }
                this.isAddAnswer = true
                this.answerObj.comment_content = this.answerText
                this.$api.custom_huidong_journal_add_comment(this.answerObj)
                .then(res=>{
                    item.journal_comments = res.data.journal_comments
                    this.answerObj = {}
                    this.answerText = ''
                    this.isAnswer = -1
                    this.isAddAnswer = false
                    this.$forceUpdate()
                })
            },
            showAnswer(index,num,item,it,con){
                let answerObj = {
                    journal_id:item.journal_id,
                    comment_entity:item.comment_entity,
                }
                if(num === 2){
                    answerObj.parentId = it.commnet_id
                }
                if(num === 3){
                    answerObj.parentId = con.commnet_id
                }
                this.answerObj = answerObj
                this.isAnswer = index
            },
            toAnchor(){
                if(this.$route.query.commnet_id){
                    document.documentElement.scrollTop = this.$refs[this.$route.query.commnet_id][0].offsetTop - 30
                }
            }
        },
    }
</script>

<style lang="less" scoped>
    .journalDetail{
        min-height: 100vh;
        background: #fff;
        padding: 0 15px;
        .journal-nav{
            position: relative;
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #eee;
            img{
                width: 28px;
                height: 28px;
                border-radius: 2px;
                margin-right: 5px;
            }
            .journal-nav-time{
                position: absolute;
                right: 0;
            }
        }
        .journal-content{
            line-height: 24px;
            padding: 10px 0;
            border-bottom: 1px solid #e9eaec;
            h1{
                margin-top: 6px;
                font-size: 20px;
            }
            .journal-content-wrap{
                background: #eee;
                padding: 10px;
                border-radius: 2px;
            }
            .journal-content-title{
                color: rgba(69, 90, 100, 0.6);
                margin: 6px 0;
            }
            .journal-content-textarea{
                border: 1px solid #e9eaec;
                border-radius: 2px;
                margin: 10px 0;
                .van-cell{
                    padding: 5px;
                }
            }
            .van-button--small{
                height: 24px;
            }
            .journal-comments-item{
                .journal-comments-wrap{
                    .journal-comments-wrap-nav{
                        display: flex;
                        align-items: center;
                        img{
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                    }
                    .journal-comments-item-icon{
                        cursor: pointer;
                    }
                    .journal-comments-item-hover:hover{
                        color: #00cca2;
                    }
                }
            }
        }
    }
</style>